import React, { Component } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import axios from "axios";
import {
  Patient_List_API,
  BOOKING_STATUSES,
  BOOKING_STATUSES_USED,
  BOOKING_STATUSES_CARD,
  CANCEL_APPOINTMENT,
  LOADER_RED,
  LOADER,
  CALL_RATING_FEEDBACK,
  CALL_RATING,
} from "../../utils/constant";
import $ from "jquery";
import HeaderComponent from "../../components/header/headerComponent";
import SubHeaderComponent from "../../components/sub-header/subHeaderComponent";
import PatientListCard from "../../components/patientlist-card/patientlistCard";
import LS_SERVICE from "../../utils/localStorage";
import ToastComponent from "../../components/toast/toast";
import Popup from "reactjs-popup";
import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import BeautyStars from 'beauty-stars';

let autoLoadData = '';

class PatientListComponent extends Component {
  /**
   * booking status 0 1 3 4 5 6
   * 0 - ALL
   * 1 - COMPLETED
   *
   */

  state = {
    doctorId: null,
    date: "",
    hospital_id: null,
    authorization_token: null,
    upcoming_loaded: false,
    missed_loaded: false,
    completed_loaded: false,
    cancelled_loaded: false,
    all_loaded: false,
    showMissedWarning: false,
    smwDismissed: false,
    booking_status: BOOKING_STATUSES.UPCOMING,
    dataLoaded: false,
    isRequestProcessing: false,
    data: [],
    page: 1,
    total_pages: 1,
    noMoreData: false,
    isUpcomingTabActive: null,
    isMissedTabActive: false,
    isCompletedTabActive: false,
    isArrivedTabActive: false,
    isAllTabActive: false,
    isCancelledTabActive: false,
    all: 0,
    arrived: 0,
    cancelled: 0,
    completed: 0,
    no_show: 0,
    upcoming: 0,
    cardCanBut: false,
    toastSuccessClass: "bg-danger",
    toast: false,
    toastmsg: "",
    openPop: false,
    handleCallFeedbackPopup: false,
    doctor_rating: 5,
    doctor_remarks: "",
    booking_id: "",
    feedback_booking_id: ""
  };

  constructor(props) {
    super(props);

    let keysToRemove = [
      "cancelled_count",
      "completed_count",
      "upcoming_count",
      "no_show_count",
      "all_count",
      "upcoming_appointments",
      "missed_appointments",
      "completed_appointments",
      "cancelled_appointments",
      "all_appointments",
    ];
    for (var key of keysToRemove) {
      LS_SERVICE.delete(key);
    }

    this.loadMore = this.loadMore.bind(this);
    this.loadListData = this.loadListData.bind(this);
    this.loadApiData = this.loadApiData.bind(this);
    this.addScrollEvent = this.addScrollEvent.bind(this);
  }

  handleScroll = () => {
    var lastLi = document.querySelector(
      "#tabs-healthvccont-tabpane-all > div > div.card:last-child"
    );
    const scrollWin = document.querySelector('.scrollContainer');
    if (lastLi === null) return;
    var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
    var pageOffset = scrollWin.scrollTop + scrollWin.clientHeight;
    if (pageOffset > lastLiOffset) {
      this.loadMore();
    }
  };

  UNSAFE_componentWillReceiveProps(props) {

    if (props.location.state !== undefined) {

      const hospitals = LS_SERVICE.get('hospitals');
      hospitals.forEach((e) => {
        if (e.id === props.location.state.hospital_id) {
          LS_SERVICE.set('active_hospital', e);
        }
      });

      let { booking_status } = this.state;

      if (LS_SERVICE.has('active_tab'))
        booking_status = BOOKING_STATUSES_USED[LS_SERVICE.get('active_tab').toUpperCase()]


      this.setState({
        hospital_id: props.location.state.hospital_id,
        is_hospital_homecare: props.location.state.is_hospital_homecare,
        data: [],
        page: 1,
        total_pages: 1,
        booking_status,
        isAllTabActive: BOOKING_STATUSES_CARD[booking_status] === "",
        // isCompletedTabActive: BOOKING_STATUSES_CARD[booking_status] === "completed",
        isCancelledTabActive: BOOKING_STATUSES_CARD[booking_status] === "cancelled",
        isMissedTabActive: BOOKING_STATUSES_CARD[booking_status] === "missed",
        isArrivedTabActive: BOOKING_STATUSES_CARD[booking_status] === "completed",
        isUpcomingTabActive: BOOKING_STATUSES_CARD[booking_status] === "upcoming",
      }, () => {
        LS_SERVICE.set('active_tab', BOOKING_STATUSES_CARD[this.state.booking_status] !== "" ? BOOKING_STATUSES_CARD[this.state.booking_status] : "all")
        this.loadListData()
      })
    }
  }

  UNSAFE_componentWillMount() {
    if (!LS_SERVICE.has('active_hospital'))
      LS_SERVICE.set('active_hospital', LS_SERVICE.get("hospitals")[0]);

    let { booking_status } = this.state;

    if (LS_SERVICE.has('active_tab'))
      booking_status = BOOKING_STATUSES_USED[LS_SERVICE.get('active_tab').toUpperCase()]

    this.setState({
      doctorId: LS_SERVICE.has("doctor") ? LS_SERVICE.get("doctor") : this.props.history.push("/"),
      hospital_id: LS_SERVICE.get("active_hospital").id,
      authorization_token: LS_SERVICE.has("authorization") ? LS_SERVICE.get("authorization") : this.props.history.push("/"),
      booking_status,
      isAllTabActive: BOOKING_STATUSES_CARD[booking_status] === "",
      // isCompletedTabActive: BOOKING_STATUSES_CARD[booking_status] === "completed",
      isCancelledTabActive: BOOKING_STATUSES_CARD[booking_status] === "cancelled",
      isMissedTabActive: BOOKING_STATUSES_CARD[booking_status] === "missed",
      isArrivedTabActive: BOOKING_STATUSES_CARD[booking_status] === "completed",
      isUpcomingTabActive: BOOKING_STATUSES_CARD[booking_status] === "upcoming",
      is_hospital_homecare: LS_SERVICE.get("active_hospital").is_homecare,
    }, () => LS_SERVICE.set('active_tab', BOOKING_STATUSES_CARD[this.state.booking_status] !== "" ? BOOKING_STATUSES_CARD[this.state.booking_status] : "all"))
  }

  componentDidMount() {
    axios.defaults.headers.common[
      "Authorization"
    ] = this.state.authorization_token;

    this.handleFeebackPopupApi();
    this.loadListData();
    this.addScrollEvent();
    LS_SERVICE.delete("is_pin_available");
    LS_SERVICE.delete("is_forgot_pin");
  }

  componentWillUnmount() {
    this.cleanupListener();
    this.clearTimerAutoLoad();
  }

  handleFeebackPopupApi = () => {
    axios.get(CALL_RATING_FEEDBACK)
      .then((Response) => {
        const data = Response.data;
        if (data.status) {
          this.setState({
            feedback_booking_id: data.booking_id
          }, () => this.handleBeautyPopup());
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handlePopupOpenClose = (value) => {
    this.setState({
      openPop: value,
    });
  };

  handleCancelAppointment = () => {
    this.resetToastState();
    const i = LS_SERVICE.get("card_cancel").index;
    const booking_id = LS_SERVICE.get("card_cancel").value;
    this.setState({
      cardCanBut: true,
    });
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );
    axios
      .post(CANCEL_APPOINTMENT, { booking_id, type: "request" })
      .then((response) => {
        const dataRes = response.data;
        this.setState({
          cardCanBut: false,
          openPop: false,
        });
        if (dataRes.is_missed) {
          this.setState({
            toast: true,
            toastmsg: dataRes.message,
          });
        } else {
          let {
            all,
            cancelled,
            upcoming,
          } = this.state;
          // when booking tab ALL is not open
          let data = [];
          if (this.state.booking_status !== 0) {
            data = this.state.data.filter((d) => d.id !== booking_id);
          } else {
            /* login to write i.e when tab is all update thr property booking_status_code */
            data = [...this.state.data];
            data[i].booking_status_code = 3;
          }
          upcoming = upcoming - 1;
          cancelled = cancelled + 1;
          this.setState({ data, cancelled, all, upcoming });
        }
      })
      .catch((error) => {
        error = error.response;
        if (error.status === 400) {
          if (error && error.data && error.data.errors.length > 0) {
            this.setState({
              toast: true,
              toastmsg: error.data.errors[0].message,
            });
          }
        }
      });
  };

  addScrollEvent = () => {
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.handleScroll, true);
  };

  loadMore = () => {
    const { noMoreData, isRequestProcessing } = this.state;

    if (noMoreData) return;
    if (isRequestProcessing === true) return;

    this.setState({
      isRequestProcessing: true,
    });

    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
      }),
      () => {
        this.loadListData(this.state.booking_status);
      }
    );
  };

  cleanupListener = () => {
    window.removeEventListener("scroll", this.handleScroll, true);
    window.removeEventListener("resize", this.handleScroll, true);
  };

  loadListData = (status = this.state.booking_status) => {
    this.setState(
      {
        booking_status: status,
        dataLoaded: false,
      }, () => this.loadApiData()
    );
  };

  dontCancel = () => {
    this.setState({
      openPop: false,
    });
  };

  loadApiData = () => {
    var today = new Date();
    var date = today.toISOString().split("T")[0];

    axios.defaults.headers.common[
      "Authorization"
    ] = this.state.authorization_token;

    const { page, data, booking_status, hospital_id } = this.state;
    axios
      .get(
        Patient_List_API +
        "booking_status=" +
        booking_status +
        "&date=" +
        date +
        "&hospital_id=" +
        hospital_id +
        "&page=" +
        page
      )
      .then((Response) => {
        const dataNew = Response.data.appointments;
        const tPages = Response.data.pagination.total_pages;
        const noData =
          Response.data.pagination.links.next === "" ? true : false;
        const {
          all,
          arrived,
          cancelled,
          completed,
          no_show,
          upcoming,
        } = Response.data.filter_count;

        this.setState({
          data: page === 1 ? dataNew : [...data, ...dataNew],
          total_pages: tPages,
          dataLoaded: true,
          all,
          arrived,
          cancelled,
          completed,
          no_show,
          upcoming,
          noMoreData: noData,
          isRequestProcessing: false,
          showMissedWarning: no_show > 0 ? true : false,
        });
        this.setTimerAutoLoad(page);
      })
      .catch((error) => {
        this.setState({
          isRequestProcessing: false,
          dataLoaded: true,
        });
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  };

  setTimerAutoLoad = (page) => {
    if (page === 1 && autoLoadData === '') {
      autoLoadData = setInterval(() => { this.loadApiData() }, 5000)
    } else if (page !== 1) {
      this.clearTimerAutoLoad();
    }
  }

  clearTimerAutoLoad = () => {
    clearInterval(autoLoadData)
    autoLoadData = '';
  }

  setShow = (showBox) => {
    this.setState({
      showMissedWarning: !showBox,
      smwDismissed: true,
    });
  };

  resetToastState() {
    this.setState({
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
    });
  };

  resetStateTabs = (val) => {
    this.setState(
      {
        data: [],
        page: 1,
        total_pages: 1,
      },
      this.loadListData(val)
    );
  };

  handleTabClick = (e, status) => {

    e.preventDefault();
    if (this.state.booking_status === status) return;
    this.clearTimerAutoLoad();

    this.setState({
      isAllTabActive: BOOKING_STATUSES_CARD[status] === "",
      // isCompletedTabActive: BOOKING_STATUSES_CARD[status] === "completed",
      isCancelledTabActive: BOOKING_STATUSES_CARD[status] === "cancelled",
      isMissedTabActive: BOOKING_STATUSES_CARD[status] === "missed",
      isArrivedTabActive: BOOKING_STATUSES_CARD[status] === "completed",
      isUpcomingTabActive: BOOKING_STATUSES_CARD[status] === "upcoming",
    }, () => LS_SERVICE.set('active_tab', BOOKING_STATUSES_CARD[status] !== "" ? BOOKING_STATUSES_CARD[status] : "all"));

    // switch (status) {
    //   case 0:
    //     this.setState({
    //       isAllTabActive: true,
    //       isCompletedTabActive: false,
    //       isCancelledTabActive: false,
    //       isMissedTabActive: false,
    //       isArrivedTabActive: false,
    //       isUpcomingTabActive: false,
    //     });
    //     break;
    //   case 1:
    //     this.setState({
    //       isAllTabActive: false,
    //       isCompletedTabActive: true,
    //       isCancelledTabActive: false,
    //       isMissedTabActive: false,
    //       isArrivedTabActive: false,
    //       isUpcomingTabActive: false,
    //     });
    //     break;
    //   case 3:
    //     this.setState({
    //       isAllTabActive: false,
    //       isCompletedTabActive: false,
    //       isCancelledTabActive: true,
    //       isMissedTabActive: false,
    //       isArrivedTabActive: false,
    //       isUpcomingTabActive: false,
    //     });
    //     break;
    //   case 4:
    //     this.setState({
    //       isAllTabActive: false,
    //       isCompletedTabActive: false,
    //       isCancelledTabActive: false,
    //       isMissedTabActive: true,
    //       isArrivedTabActive: false,
    //       isUpcomingTabActive: false,
    //     });
    //     break;
    //   case 5:
    //     this.setState({
    //       isAllTabActive: false,
    //       isCompletedTabActive: false,
    //       isCancelledTabActive: false,
    //       isMissedTabActive: false,
    //       isArrivedTabActive: true,
    //       isUpcomingTabActive: false,
    //     });
    //     break;
    //   case 6:
    //     this.setState({
    //       isAllTabActive: false,
    //       isCompletedTabActive: false,
    //       isCancelledTabActive: false,
    //       isMissedTabActive: false,
    //       isArrivedTabActive: false,
    //       isUpcomingTabActive: true,
    //     });
    //     break;
    //   //----------------- added default case
    //   default:
    //     this.setState({
    //       isAllTabActive: false,
    //       isCompletedTabActive: false,
    //       isCancelledTabActive: false,
    //       isMissedTabActive: false,
    //       isArrivedTabActive: false,
    //       isUpcomingTabActive: false,
    //     });
    //     break;
    // }
    this.resetStateTabs(status);
  };

  /** Beauty Popup Functions */

  handleInputChange = e => {
    const target = e.target;
    this.setState({ [target.name]: target.value })
  }

  handleBeautyPopup = _ => {
    this.setState({
      handleCallFeedbackPopup: !this.state.handleCallFeedbackPopup
    });
  }

  setDoctorRating = (doctor_rating) => {
    this.setState({
      doctor_rating
    }, () => {
      if (this.state.doctor_rating > 0) {
        $('.error-beautystars').addClass('hidden');
      }
    })
  }

  handleDontRate = _ => {
    this.handleCallRatings(null, 2);
  };

  handleBeautyPopupSubmit = (e) => {
    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').toggleClass('loader-active');
    }

    const { doctor_rating } = this.state;

    if (doctor_rating === 0) {
      $(elem).find('span.loader-span').toggleClass('loader-active');
      $('.error-beautystars').removeClass('hidden');
      return;
    }

    this.handleCallRatings(e, 2);
  }

  handleCallRatings = (e, status) => {

    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').addClass('loader-active');
    }

    this.resetToastState();

    const { doctor_rating, doctor_remarks, feedback_booking_id } = this.state;

    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    axios
      .post(CALL_RATING, {
        booking_id: feedback_booking_id,
        doctor_remarks,
        doctor_rating,
        state: status // 2 for feedback Submit 1 for Call end and 0 for call start
      })
      .then((Response) => {
        if (status === 2) {
          if (elem !== null)
            $(elem).find('span.loader-span').removeClass('loader-active');
          this.setState({
            handleCallFeedbackPopup: false
          })
        }
      })
      .catch((error) => {
        if (elem !== null)
          $(elem).find('span.loader-span').removeClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  /** Beautify Popup Functions */



  render() {
    const {
      isAllTabActive,
      isMissedTabActive,
      isCancelledTabActive,
      isArrivedTabActive,
      isUpcomingTabActive,
    } = this.state;
    return (
      <>
        <HeaderComponent />
        <SubHeaderComponent />
        <Container className="mt-0 mb-0 tabs-healtvc custom-container">
          <div>
            <div className="d-flex align-items-center row">
              <div className="mt-4 mb-4 col-md-9">
                <div className="nav nav-pills nav-justified" role="tablist">
                  <div className="nav-item">
                    <a
                      href="/upcoming"
                      role="tab"
                      data-rb-event-key="upcoming"
                      id="tabs-healthvccont-tab-upcoming"
                      aria-controls="tabs-healthvccont-tabpane-upcoming"
                      tabIndex="-1"
                      aria-selected="false"
                      className={`${isUpcomingTabActive ? "active" : ""
                        } h4 m-0 border rounded-0 d-flex align-items-center justify-content-center nav-link`}
                      onClick={(e) =>
                        this.handleTabClick(e, BOOKING_STATUSES.UPCOMING)
                      }
                    >
                      Upcoming{" "}
                      <span className="bg-transparent font-weight-bolder m-0 badge">
                        {this.state.upcoming || 0}
                      </span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="/missed"
                      role="tab"
                      data-rb-event-key="missed"
                      id="tabs-healthvccont-tab-missed"
                      aria-controls="tabs-healthvccont-tabpane-missed"
                      tabIndex="-1"
                      aria-selected="false"
                      className={`${isMissedTabActive ? "active" : ""
                        } h4 m-0 border rounded-0 d-flex align-items-center justify-content-center nav-link`}
                      onClick={(e) =>
                        this.handleTabClick(e, BOOKING_STATUSES.MISSED)
                      }
                    >
                      Missed
                      <span className="bg-transparent font-weight-bolder m-0 badge">
                        {this.state.no_show || 0}
                      </span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="/completed"
                      role="tab"
                      data-rb-event-key="completed"
                      id="tabs-healthvccont-tab-completed"
                      aria-controls="tabs-healthvccont-tabpane-completed"
                      tabIndex="-1"
                      aria-selected="false"
                      className={`${isArrivedTabActive ? "active" : ""
                        } h4 m-0 border rounded-0 d-flex align-items-center justify-content-center nav-link`}
                      onClick={(e) =>
                        this.handleTabClick(e, BOOKING_STATUSES.ARRIVED)
                      }
                    >
                      Completed{" "}
                      <span className="bg-transparen font-weight-bolder m-0 badge">
                        {this.state.arrived || 0}
                      </span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="/cancelled"
                      role="tab"
                      data-rb-event-key="cancelled"
                      id="tabs-healthvccont-tab-cancelled"
                      aria-controls="tabs-healthvccont-tabpane-cancelled"
                      tabIndex="-1"
                      aria-selected="false"
                      className={`${isCancelledTabActive ? "active" : ""
                        } h4 m-0 border rounded-0 d-flex align-items-center justify-content-center nav-link`}
                      onClick={(e) =>
                        this.handleTabClick(e, BOOKING_STATUSES.CANCELLED)
                      }
                    >
                      Cancelled{" "}
                      <span className="bg-transparen font-weight-bolder m-0 badge">
                        {this.state.cancelled || 0}
                      </span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="/all"
                      role="tab"
                      data-rb-event-key="all"
                      id="tabs-healthvccont-tab-all"
                      aria-controls="tabs-healthvccont-tabpane-all"
                      aria-selected="true"
                      className={`${isAllTabActive ? "active" : ""
                        } h4 m-0 border rounded-0 d-flex align-items-center justify-content-center nav-link`}
                      onClick={(e) =>
                        this.handleTabClick(e, BOOKING_STATUSES.ALL)
                      }
                    >
                      All{" "}
                      <span className="bg-transparent m-0 font-weight-bolder badge">
                        {this.state.all || 0}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <Alert
                  show={
                    this.state.showMissedWarning && !this.state.smwDismissed
                  }
                  className="mb-0 pl-alert showMissedWrn"
                >
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={9}>
                      <Alert.Heading
                        className="h5 m-0"
                      >
                        You have MISSED
                      </Alert.Heading>
                      <p
                        className="h5 m-0"
                      >
                        {this.state.no_show || 0} appointment
                        {this.state.no_show > 1 ? "s" : ""}
                      </p>
                    </Col>
                    <Col md={3} className="d-flex justify-content-end">
                      <Button
                        onClick={() =>
                          this.setShow(this.state.showMissedWarning)
                        }
                        style={{ background: "#5C5C5C" }}
                        className="text-white font-weight-bold border-0"
                      >
                        OK
                      </Button>
                    </Col>
                  </Row>
                </Alert>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div
              booking_type="0"
              id="tabs-healthvccont-tabpane-all"
              aria-labelledby="tabs-healthvccont-tab-all"
              role="tabpanel"
              aria-hidden="false"
              className="fade tab-pane active show"
            >
              {this.state.data.length > 0 ? (
                <PatientListCard
                  appointments={this.state.data}
                  onCancelAppointment={() => this.handlePopupOpenClose(true)}
                />
              ) : this.state.dataLoaded && this.state.data.length === 0 ? (
                <PatientListCard
                  appointments={this.state.data}
                  onCancelAppointment={() => this.handlePopupOpenClose(true)}
                  booking_name={BOOKING_STATUSES_CARD[this.state.booking_status]}
                />
              ) : (
                    ""
                  )}

              <div
                className="d-flex justify-content-center align-self-center mb-3"
                style={{
                  visibility: `${!this.state.dataLoaded ? "visible" : "hidden"
                    }`,
                }}
              >
                {LOADER_RED}
              </div>
            </div>
          </div>
        </Container>
        <ToastComponent
          successClass={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />
        {/*-------------- cancel appointment Popup-----------------------*/}
        <Popup
          className="cancel-pop"
          open={this.state.openPop}
          onClose={() => this.handlePopupOpenClose(false)}
          // closeOnDocumentClick
          modal
        >
          <div className="text-center">

            <div className="content mt-5 ml-3 mr-3">
              <p
                className="doc-popup-text text-center mb-4"
              >
                <h5> Are you sure you want to cancel this Appointment?</h5>
              </p>
            </div>
            <div className="pr-3 button text-center">
              <button
                className={`${this.state.cardCanBut === true ? "target" : ""
                  } btn btn-danger mr-2 mb-2`}
                onClick={this.handleCancelAppointment}
              >
                {" "}
                Yes
                <span style={{ display: "none" }}>{LOADER}</span>
              </button>
              <button
                className={`${this.state.cardCanBut === true ? "target" : ""
                  } btn btn-outline-danger mb-2`}
                onClick={this.dontCancel}
              >
                {" "}
                No
              </button>
            </div>
          </div>
        </Popup>

        <Modal isOpen={this.state.handleCallFeedbackPopup}>
          <ModalHeader
            className="text-center bg-grey">Please rate the quality of your last call</ModalHeader>
          <ModalBody className="text-center">
            <div className="beautify_wrapper d-flex flex-column">
              <BeautyStars
                activeColor="#d24350"
                inactiveColor="#F0F0F0"
                value={this.state.doctor_rating}
                onChange={doctor_rating => this.setDoctorRating(doctor_rating)} />
              <span className="mt-3 hidden error-beautystars text-danger">Please rate to Submit!</span>
            </div>
            <br />
            <Input type="textarea" placeholder="Remarks" name="doctor_remarks" value={this.state.doctor_remarks} rows={3}
              onChange={this.handleInputChange} />
          </ModalBody>
          <div className="d-flex text-center modal-header p-0">
            <div className="row justify-content-center">
              <div className="col-md-auto">
                <Button className="button-healthvc button-variant-cancel font-weight-bold btn-secondary"
                  type="button" onClick={this.handleDontRate} >
                  Not now
                </Button>
              </div>
              <div className="col-md-auto">
                <Button className="button-healthvc button-variant-red mb-3 font-weight-bold btn btn-primary"
                  type="button" onClick={this.handleBeautyPopupSubmit} >
                  Submit
                <span className="loader-span">{LOADER}</span>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default PatientListComponent;
