import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import $ from "jquery";
import PDF_ICO from "../../assets/images/pdf.png";
import JPG_ICO from "../../assets/images/jpeg.png";
import PRINT_ICO from "../../assets/images/print.png";
import rotateLeft from "../../assets/images/rotate-left.png";
import rotateRight from "../../assets/images/rotate-right.png";
import prevImgIcon from "../../assets/images/previous-icon.svg";
import nextImgIcon from "../../assets/images/next-icon.svg";
import Moment from "react-moment";
import axios from "axios";
import {
  LATE_APPOINTMENT_TIME,
  SEPARATEUPLOADEDDOCS,
  GETGENDER,
  UHID, API_BASE_URL2,
  HANDLEIMAGEROTATE,
  IMAGEPREVNEXTRESET,
  GETURLEXTENSION
} from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";
import QbHelpers from '../../helpers/QbHelpers';

function PatientListCard(props) {


  const appointments = props.appointments;
  const [modal, setModal] = useState(false);
  const [dataFile, setDataFile] = useState("");
  const [modalClass, setModalClass] = useState("modal-imgdocs");
  const [showArrow, setShowArrow] = useState(false);
  const [allDocuments, setAllDocuments] = useState([]);
  const [documentCount, setDocumentCount] = useState(0);
  const [documentIndex, setDocumentIndex] = useState(0);
  const [documentType, setDocumentType] = useState("");
  const [isSafari, setIsSafari] = useState(false);

  const documentToggle = (e, files, isDoc = false) => {
    if (isDoc) {
      setModalClass("modal-imgdocs modal-docs");
      setDocumentType('application/pdf');
    } else {
      setModalClass("modal-imgdocs");
      setDocumentType('');
    }
    if (e !== null) {
      e.preventDefault();
      if (files !== undefined) {
        setDocumentIndex($(e.currentTarget).index());
        setDocumentCount(e.currentTarget.parentElement.childElementCount);
        setAllDocuments(files);
        setDataFile(files[$(e.currentTarget).index()]);
      }
    } else {
      setDataFile(files);
    }
    setModal(!modal);
    isSafariTest();
  };

  const handleNextPrev = (e) => {
    e.preventDefault();
    let docIndex = documentIndex;
    const targetName = e.currentTarget;
    if (targetName.getAttribute("name") === "image-left") {
      if (docIndex > 0) {
        setDocumentIndex(documentIndex - 1);
        setDocumentType(GETURLEXTENSION(allDocuments[docIndex - 1]));
        setDataFile(allDocuments[docIndex - 1]);
      }
    } else {
      if (docIndex < (documentCount - 1)) {
        setDocumentIndex(documentIndex + 1);
        setDocumentType(GETURLEXTENSION(allDocuments[docIndex + 1]));
        setDataFile(allDocuments[docIndex + 1]);
      }
    }
  }

  useEffect(() => {
    if (documentCount > 1) {
      setShowArrow(true);
    }
    if (documentType === "application/pdf") {
      setModalClass('modal-imgdocs modal-docs');
      IMAGEPREVNEXTRESET(dataFile);
    } else {
      setModalClass('modal-imgdocs');
      if (dataFile !== "")
        IMAGEPREVNEXTRESET(dataFile);
    }
  }, [documentCount, documentType, dataFile])

  function handleClickNav(booking_id, booking_status_code) {
    props.history.push({
      pathname: "/doctor-consult",
      state: { booking_id: booking_id, booking_status_code: booking_status_code },
    });
  }

  function timeDiff(booking_time) {
    booking_time = booking_time.split(":");
    var todayDate = new Date();
    var dd = todayDate.getDate();
    var mm = todayDate.getMonth(); //January is 0!
    var yyyy = todayDate.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    var arrived_time = new Date(
      Date.UTC(
        yyyy,
        mm,
        dd,
        booking_time[0],
        booking_time[1],
        booking_time[2]
      ) +
      todayDate.getTimezoneOffset() * 60 * 1000
    );
    var today = new Date();
    return today - arrived_time;
  }

  String.prototype.replaceAll = function (str1, str2, ignore) {
    return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"), (ignore ? "gi" : "g")), (typeof (str2) == "string") ? str2.replace(/\$/g, "$$$$") : str2);
  }

  function trimString(notesString) {
    var maxLength = 165;
    var trimmedString = "";
    notesString = notesString.replaceAll('\\n', ' ');
    if (notesString && notesString.length < maxLength) {
      trimmedString = notesString
    }
    else if (notesString && notesString.length > trimmedString) {
      trimmedString = notesString.substr(0, maxLength);
      trimmedString = trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      );
      trimmedString = trimmedString + '...'
    }
    return trimmedString;
  }

  function onCancelAppoint(index, value) {
    LS_SERVICE.set("card_cancel", { index, value });
    props.onCancelAppointment();
  }

  function openPrescriptionDoc(e, booking_id) {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
    axios.get(API_BASE_URL2 + "bookings/" + booking_id + "/prescriptions?mode=web")
      .then((Response) => {
        var pdfUrl = Response.data;
        documentToggle(null, pdfUrl, true)
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  const hideCancelButton = (value, otherdetails) => {
    let isCancel = true;
    const { diffHours, diffMins } = otherdetails;

    if (value.is_cancel) {
      if (diffHours > 0 || (diffHours <= 0 && diffMins > 0))
        isCancel = false;
      return ((typeof LS_SERVICE.get('vc_booking_id') !== 'undefined' && LS_SERVICE.get('vc_booking_id') == value.id && (QbHelpers.isCallActive || window.InncVonage.data.call_started)) || !isCancel);
    } else {
      return true;
    }
  }


  const cards = [];
  appointments.forEach((value, index) => {

    const today = new Date();
    const diff = timeDiff(value.booking_time);
    const diffHours = diff > 0 ? Math.floor(diff / 3600000) : 0;
    const diffMins = diff > 0 ? Math.floor(diff / 60 / 1000 - 60 * diffHours) : 0;
    const uploads = SEPARATEUPLOADEDDOCS(value.patient_docments);

    cards.push(
      <Card className="mb-3" key={index}>
        <Card.Body className="card-bodycustom">
          <Row>
            <Col md={2}>
              <div>
                <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-2"
                  onClick={() => value.booking_status_code == 5 ? handleClickNav(value.id, value.booking_status_code) : ''}
                  style={{ cursor: value.booking_status_code == 5 ? 'pointer' : 'default' }}
                >
                  {value.patient.first_name
                    ? value.patient.first_name.toLowerCase()
                    : ""}{" "}
                  {value.patient.last_name
                    ? value.patient.last_name.toLowerCase()
                    : ""}
                </Card.Title>
                <Card.Subtitle className="mb-2 card-light-gray h5" style={{ whiteSpace: 'nowrap' }}>
                  <span
                    className={
                      value.patient.dob === "0000-00-00" || value.patient.dob === null ? "hidden" : ""
                    }
                  >
                    <Moment
                      diff={
                        value.patient.dob !== "0000-00-00"
                          ? value.patient.dob
                          : new Date()
                      }
                      unit="years"
                    >
                      {today}
                    </Moment>{" "}
                    Yrs,
                  </span>{" "}
                  {GETGENDER(value.patient.gender)}
                  {UHID(value.patient.uhid) !== '' ? ' | ' + value.patient.uhid : ''}
                </Card.Subtitle>
                <h6 className="healtvc-apptime text-danger h5 mt-1 mb-0 d-inline-flex justify-content-center align-items-center">
                  <Moment format="h:mm A" parse="HH:mm:ss">
                    {value.booking_time}
                  </Moment>
                  {(diffHours >= 1 || diffMins > LATE_APPOINTMENT_TIME) && (value.booking_status_code === 1 || value.booking_status_code === 6) ? (
                    <span
                      className="late-span btn btn-danger btn-sm font-weight-bold rounded-0 cursor-default"
                    >
                      LATE
                    </span>
                  ) : null}
                  {value.is_draft_flag ? <span className="draft-saved mx-2">Draft Saved</span> : null}
                </h6>
                <div className="tags_wrapper d-flex align-items-center justify-content-start mt-1">
                  {value.currency_type == 'USD' ?
                    <p className="font-weight-bold m-0 mr-2 pr-2 intl-tag">INTL</p> : ""}
                  {/* {value.is_php ?
                    <p className="font-weight-bold m-0">PHP</p> : ""} */}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="pl-2">
                <Card.Title className="healtvc-card-title text-dark mb-2">
                  {value.patient_note_doctor ? (
                    <span className="healthvc-tick"></span>
                  ) : null}
                  Notes for Doctor
                </Card.Title>
                <Card.Text className="card-light-gray pc-doctor-notes pcd-notes">
                  {value.patient_note_doctor !== null
                    ? trimString(value.patient_note_doctor)
                    : "Nil"}
                </Card.Text>
              </div>
            </Col>
            <Col md={2}>
              <div className="pl-2">
                <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-2">
                  {!uploads.fileImg.length > 0 &&
                    !uploads.fileDoc.length > 0 ? null : (
                      <span className="healthvc-tick"></span>
                    )}
                  Documents Uploaded
                </Card.Title>
                <Card.Text className="pc-doctor-notes">
                  {!uploads.fileImg.length > 0 && !uploads.fileDoc.length > 0
                    ? "Nil"
                    : null}
                  {uploads.fileImg.length > 0
                    ? uploads.fileImg.map((file, i) => {
                      return (
                        <a
                          href="!#"
                          title="documents"
                          key={i}
                          onClick={(e) => documentToggle(e, [...uploads.fileImg, ...uploads.fileDoc])}
                        >
                          <Image
                            src={JPG_ICO}
                            alt="PDF ICON"
                          />
                        </a>
                      );
                    })
                    : null}
                  {uploads.fileDoc.length > 0
                    ? uploads.fileDoc.map((file, i) => {
                      return (
                        <a
                          href="!#"
                          title="documents"
                          key={i}
                          onClick={(e) => documentToggle(e, [...uploads.fileImg, ...uploads.fileDoc], true)}
                        >
                          <Image
                            src={PDF_ICO}
                            alt="PDF ICON"
                          />
                        </a>
                      );
                    })
                    : null}
                </Card.Text>
              </div>
            </Col>
            <Col md={2}>
              {value.booking_status_code === 1 || value.booking_status_code === 6 ? (
                <div className="d-flex flex-column justify-content-around">
                  <Button
                    className="button-healthvc button-variant-red mb-3 font-weight-bold"
                    onClick={() => handleClickNav(value.id, value.booking_status_code)}
                  >
                    START CONSULT
                  </Button>
                  {hideCancelButton(value, { diffHours, diffMins }) ? '' : <Button
                    variant="outline-secondary"
                    className={`button-healthvc button-variant-cancel font-weight-bold`}
                    onClick={() => onCancelAppoint(index, value.id)}
                  >
                    CANCEL{" "}
                  </Button>}
                </div>
              ) : null}
              {value.booking_status_code === 5 ? (
                <div className="d-flex flex-column justify-content-around">
                  <Button
                    className="button-healthvc button-variant-red-view mb-3 font-weight-bold"
                    // onClick={() => handleClickNav(value.id, value.booking_status_code)}
                    onClick={(e) => openPrescriptionDoc(e, value.id)}
                  >
                    VIEW Rx
                  </Button>
                </div>
              ) : null}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  });

  if (cards.length === 0) {
    cards.push(
      <Card className="mb-3" key={cards.length}>
        <Card.Body>
          <Row>
            <Col>
              <Card.Title
                className="text-center no-appointement mb-2"
              >
                No {props.booking_name} appointments today!
              </Card.Title>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }



  function PrintImage(src) {
    var win = window.open('<myfile>.pdf', 'my window', 'resizable,scrollbars');
    win.document.open();
    win.document.title = "pdf-test"
    win.document.write([
      '<html>',
      '   <head>',
      '   <title>pdf page to download</title>',
      '   </head>',
      '   <body onload="window.print()" onafterprint="window.close()">',
      '       <img style="max-width:600px; max-height:1000px" src="' + src + '"/>',
      '   </body>',
      '</html>'
    ].join(''));
    win.document.close();
  }

  function isSafariTest() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        setIsSafari(false) // Chrome
      } else {
        setIsSafari(true) // Safari
      }
    }
  }

  return (
    <>
      <div>{cards}</div>
      <Modal isOpen={modal} toggle={documentToggle} className={modalClass}>
        <ModalHeader toggle={documentToggle}></ModalHeader>
        <ModalBody>
          <div className="img-rotate">
            <i className="ir-left" onClick={HANDLEIMAGEROTATE} name="rotate-left"><img className="rotate-left" src={rotateLeft} /></i>
            <i className="ir-right" onClick={HANDLEIMAGEROTATE} name="rotate-right"><img className="rotate-left" src={rotateRight} /></i>
            <i className="ir-print " onClick={() => PrintImage(dataFile)} name="image-right"><img className="print" src={PRINT_ICO} /></i>
          </div>
          <div className={`img-safari-printIcon ${modalClass}`} style={{ visibility: `${isSafari ? "visible" : "hidden"}` }}>
            <i className="ir-print mr-5" onClick={() => PrintImage(dataFile)} name="image-right"><img className="print" src={PRINT_ICO} /></i>
          </div>
          <div className="img-arrows" style={{ 'display': showArrow ? 'flex' : 'none' }}>
            <i className="ir-left" onClick={handleNextPrev} name="image-left"><img className="image-left" src={prevImgIcon} /></i>
            <i className="ir-right" onClick={handleNextPrev} name="image-right"><img className="image-left" src={nextImgIcon} /></i>
          </div>
          <div className="object-wrapper ow-fullheight">
           <object title="Document" data={dataFile} type={documentType} data-rotate="0" id="objDoc"></object>
            </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withRouter(PatientListCard);
