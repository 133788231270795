module.exports = {
    videoCallScripts: [{
            src: "/assets/js/quickblox/config.js?v="+Math.random(),
            tag: 'config'
        },
        // {
        //     src: "/assets/js/quickblox/qbMediaRecorder2.0.0.js",
        //     tag: 'qbMediaRecorder'
        // },
        {
            src: "/assets/js/quickblox/quickblox.min.2.12.7.js?v="+Math.random(),
            tag: 'quickblox'
        }
    ],
    quickbloxPwd: 'admin123',
    quickblox_mode: 'production' // environment, can be 'production' or 'development' keep it on production
};