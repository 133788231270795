import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Col, Button } from "react-bootstrap";
import axios from "axios";
import LS_SERVICE from "../../utils/localStorage";
import { LOGIN_API, LOADER, PIN_REQ_API } from "../../utils/constant";
import ToastComponent from "../../components/toast/toast";

function LoginForm(props) {
  function checkIfLoggedIn(props) {
    if (LS_SERVICE.get("is_authenticated"))
      props.history.push("/patient-lists");
  }

  checkIfLoggedIn(props);

  const [spinner, setSpinner] = useState(false);
  const [subBut, setSubBut] = useState(true);
  const [validated, setValidated] = useState(false);
  const [uid, setUid] = useState("");
  const [toast, setToast] = useState(false);
  const [toastmsg, setToastmsg] = useState("");
  const [toastSuccessClass, settoastSuccessClass] = useState("bg-danger");

  const resetToastState = (event) => {
    setToast(false);
    setToastmsg("");
    settoastSuccessClass("bg-danger");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    resetToastState();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      /** CHECK VALIDITY FALSE MEANS NOT VALID FORM ELSE VALID */
    } else {
      setSubBut(true);
      setSpinner(true);
      axios
        .post(PIN_REQ_API, { uid })
        .then((Response) => {
          const data = Response.data;
          // setSubBut(false);
          // setSpinner(false);
          // LS_SERVICE.set("uid", uid);
          // props.history.push("/otp");
          LS_SERVICE.set('is_pin_available', data.is_pin_available);
          LS_SERVICE.set("uid", uid);
          props.history.push(data.is_pin_available ? '/pin' : '/otp');
          // userPinFunction(uid, data.is_pin_available);
        })
        .catch((error) => {
          setSubBut(false);
          setSpinner(false);
          error = error.response;
          if (error && error.status === 400) {
            if (error && error.data && error.data.errors.length > 0) {
              setToastmsg(error.data.errors[0].message);
              setToast(true);
            }
          } else {
            setToastmsg(
              "Some error occured. Please try again after some time."
            );
            setToast(true);
          }
        });
    }
    setValidated(true);
  };

  // const userPinFunction = (uid, is_pin_available) => {
  //   axios
  //     .post(LOGIN_API, { uid })
  //     .then((Response) => {
  //       // const data = Response.data;
  //       // LS_SERVICE.set('is_pin_available', data.is_pin_available);
  //       // LS_SERVICE.set("uid", uid);
  //       setSubBut(false);
  //       setSpinner(false);
  //       props.history.push(is_pin_available ? '/pin' : '/otp');
  //     })
  //     .catch((error) => {
  //       setSubBut(false);
  //       setSpinner(false);
  //       error = error.response;
  //       if (error && error.status === 400) {
  //         if (error && error.data && error.data.errors.length > 0) {
  //           setToastmsg(error.data.errors[0].message);
  //           setToast(true);
  //         }
  //       } else {
  //         setToastmsg(
  //           "Some error occured. Please try again after some time."
  //         );
  //         setToast(true);
  //       }
  //     });
  // }

  const handleSetUidChange = (event) => {
    let uidnew = event.target.value.replace(/\D/, "");
    setUid(uidnew);
    uidnew.length > 1 ? setSubBut(false) : setSubBut(true);
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group
            as={Col}
            className="login-input"
            controlId="validationCustom01"
          >
            <Form.Control
              className="input-text"
              required
              type="text"
              minLength="10"
              maxLength="10"
              placeholder="Doctor's Mobile Number"
              value={uid}
              onChange={handleSetUidChange}
            />
            <Form.Control.Feedback type="invalid" className="text-left">
              Enter valid Mobile Number.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col>
            <Button
              type="submit"
              className="login-button btn btn-danger"
              disabled={subBut ? true : false}
            >
              LOGIN {spinner ? LOADER : ""}
            </Button>
          </Col>
        </Form.Row>
      </Form>

      <ToastComponent
        successClass={toastSuccessClass}
        show={toast}
        message={toastmsg}
      />
    </>
  );
}

export default withRouter(LoginForm);
